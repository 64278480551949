import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Link, graphql } from 'gatsby';
import {
  LayoutLp,
  SEO,
  AssetImage,
  LWrap,
  CBtnList,
  // CCenterSlider,
  CLabelCardSlider,
  CustomLink
} from '../../../components/_index';
import { useMobile } from '../../../utils/use-series';
import '../../../assets/_sass/page/sp/christmas2024.scss';
export type headerLanguageProps = Array<{
  link: {
    href: string;
    blank?: boolean;
  };
  label: string;
  current?: boolean;
}>;
export const headerLanguage: headerLanguageProps = [
  {
    link: {
      href: '#sapporo',
    },
    label: '札幌・仙台',
  },
  {
    link: {
      href: '#tokyo',
    },
    label: '東京・横浜',
  },
  {
    link: {
      href: '#nagoya',
    },
    label: '名古屋',
  },
  {
    link: {
      href: '#kyoto',
    },
    label: '京都',
  },
  {
    link: {
      href: '#osaka',
    },
    label: '大阪・神戸・広島',
  },
  {
    link: {
      href: '#fukuoka',
    },
    label: '福岡',
  },
  {
    link: {
      href: '#okinawa',
    },
    label: '沖縄',
  },
];
// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const isSp = useMobile();

  const Tagname = 'p';
  const [changeLogo, setChangeLogo] = useState(false);
  const [scrollFlag, setScrollFlag] = useState<Boolean>(true);
  const timerID = useRef<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    window.addEventListener('scroll', scrollEvent);
    return () => window.removeEventListener('scroll', scrollEvent);
  }, []);
  const test = useRef<boolean>();
  const scrollEvent = useCallback(() => {
    test.current = true;
    if (
      window.scrollY > document.querySelectorAll('.l_headerLp')[0].clientHeight
    ) {
      setScrollFlag(true);
      setChangeLogo(true);
    } else {
      setChangeLogo(false);
    }
    if (timerID.current) {
      clearTimeout(timerID.current);
    }
    timerID.current = setTimeout(() => {
      if (
        window.scrollY >
        document.querySelectorAll('.l_headerLp')[0].clientHeight
      ) {
        setScrollFlag(false);
      }
    }, 300);
  }, [scrollFlag]);
  const logo = {
    img: {
      src: `/assets/images/common/logo.svg`,
      alt: 'ROYAL PARK HOTELS',
    },
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownClose = (e: any) => {
    setDropdownOpen(false)
  }



  return (
    <LayoutLp hideHeader={true}>
      <SEO
        title={frontmatter?.title}
        description={frontmatter?.description}
        ogimage={`${process.env.BASE_URL}/assets/images/sp/christmas2024/ogimage.png`}
      />
      <header className={`l_headerLp l_headerLpChristmas2024 ${!scrollFlag ? 'is_fixed' : ''}`}>
        <div className="container">
          <div className="inner">
            {!isSp ? (
              <Tagname className="logo">
                <Link to="/">
                  <AssetImage
                    src={logo.img.src}
                    alt={logo.img.alt}
                    loading="lazy"
                  />
                </Link>
              </Tagname>
            ) : (
              <div className='sp_top'><Link to="/">TOPに戻る</Link></div>
            )}

            <div className="languageWrapper">
              <div className="languageInner">
                <button
                  className="languageDropdown"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  全国のホテルを探す{!isSp && (<span>↓</span>)}
                </button>
                {dropdownOpen && (
                  <div className="languageDropdown_menu" onClick={dropdownClose}>
                    {headerLanguage.map((item: any, index) => {
                      return (
                        <a
                          key={index}
                          href={item.link.href}
                          rel="noopener noreferrer"
                          className={item.current ? 'is_current' : ''}
                        >
                          {item.label}
                        </a>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
      <main>
        <div className="christmas2024">
          <section className="lp_kv">
            {!isSp ? (
              <figure className="imgWrapper">
                <AssetImage
                  src="/assets/images/sp/christmas2024/kv.png"
                  alt=""
                  loading="lazy"
                />
              </figure>
            ) : (
              <figure className="imgWrapper">
                <AssetImage
                  src="/assets/images/sp/christmas2024/kv__sp.png"
                  alt=""
                  loading="lazy"
                />
              </figure>
            )}
          </section>
          <div className="lp_kvLead">
            <div className="lp_kvLeadTitle">
              ロイヤルパークホテルズ<br />
              CHRISTMAS 2024
            </div>
            <div className="lp_kvLeadText">
              今年もロイヤルパークホテルズで<br className="u_sp" />素敵なクリスマスをお過ごしください！
            </div>
          </div>
          <div className="c_infinityMediaUnit">
            <div className="c_infinityMedia">
              <div className="imgWrapper">

                <CLabelCardSlider
                  data={[
                    {
                      label: '',
                      img: {
                        src: '/assets/images/sp/christmas2024/img_infinity.png',
                        alt: '',
                      },
                      title: <></>,
                      link: {
                        href: 'https://www.yrph.com/stay/plan/?tag=%23%E3%82%AF%E3%83%AA%E3%82%B9%E3%83%9E%E3%82%B9',
                      },
                    },
                    {
                      label: '',
                      img: {
                        src: '/assets/images/sp/christmas2024/img_infinity02.png',
                        alt: '',
                      },
                      title: <></>,
                      link: {
                        href: 'https://www.yrph.com/restaurants/pickup/?tag=%23%E3%82%AF%E3%83%AA%E3%82%B9%E3%83%9E%E3%82%B9',
                      },
                    },
                    {
                      label: '',
                      img: {
                        src: '/assets/images/sp/christmas2024/img_infinity03.png',
                        alt: '',
                      },
                      title: <></>,
                      link: {
                        href: 'https://www.yrph.com/information/ni4l2t11a/',
                      },
                    },
                    {
                      label: '',
                      img: {
                        src: '/assets/images/sp/christmas2024/img_infinity04.png',
                        alt: '',
                      },
                      title: <></>,
                      link: {
                        href: 'https://www.rph.co.jp/stay/plan/xsj0qb3hk/',
                      },
                    },
                    {
                      label: '',
                      img: {
                        src: '/assets/images/sp/christmas2024/img_infinity05.png',
                        alt: '',
                      },
                      title: <></>,
                      link: {
                        href: 'https://www.rph.co.jp/information/y0x2thhg922t/',
                      },
                    },
                    {
                      label: '',
                      img: {
                        src: '/assets/images/sp/christmas2024/img_infinity06.png',
                        alt: '',
                      },
                      title: <></>,
                      link: {
                        href: 'https://www.rph.co.jp/sp/christmas2024/#takeout',
                      },
                    },
                    {
                      label: '',
                      img: {
                        src: '/assets/images/sp/christmas2024/img_infinity07.png',
                        alt: '',
                      },
                      title: <></>,
                      link: {
                        href: 'https://www.srph.co.jp/pickup/7yckrjt69d/',
                      },
                    },
                    {
                      label: '',
                      img: {
                        src: '/assets/images/sp/christmas2024/img_infinity08.png',
                        alt: '',
                      },
                      title: <></>,
                      link: {
                        href: 'https://www.royalparkhotels.co.jp/ic/tokyoshiodome/news/3mrsx41aiu/',
                      },
                    },
                    {
                      label: '',
                      img: {
                        src: '/assets/images/sp/christmas2024/img_infinity09.png',
                        alt: '',
                      },
                      title: <></>,
                      link: {
                        href: 'https://www.royalparkhotels.co.jp/ic/osakamidosuji/news/t4798st7p9/',
                      },
                    },
                    {
                      label: '',
                      img: {
                        src: '/assets/images/sp/christmas2024/img_infinity10.png',
                        alt: '',
                      },
                      title: <></>,
                      link: {
                        href: 'https://www.royalparkhotels.co.jp/the/tokyohaneda/news/kdy7ohctjv/',
                      },
                    },
                  ]}
                />


              </div>
            </div>
          </div>


          <LWrap exClass="u_mbExLarge">
            <section className='lp_map'>

              <Link
                to="https://www.royalparkhotels.co.jp/pickup/dibsiy9h3/"
                target="_blank"
              >
                <AssetImage src="/assets/images/sp/christmas2024/img_banner.png" alt="" loading="lazy" />
              </Link>

            </section>
          </LWrap>
          <LWrap exClass="u_mbExLarge">
            <h3 className="lp_areaTitle">
              <span className='en'>FIND HOTELS</span>
              <span className='ja'>ロイヤルパークホテルズを探す</span>
            </h3>
            <section className='lp_map'>
              <AssetImage src="/assets/images/sp/christmas2024/map.png" alt="" loading="lazy" />
              <div className='pin_sapporo'>
                <CustomLink to="#sapporo">
                  <span className="dli-pin-fill"></span>
                  <div className="cp_pulse"></div>
                </CustomLink>
              </div>
              <div className='pin_sendai'>
                <CustomLink to="#sapporo">
                  <span className="dli-pin-fill"></span>
                  <div className="cp_pulse"></div>
                </CustomLink>
              </div>
              <div className='pin_tokyo'>
                <CustomLink to="#tokyo">
                  <span className="dli-pin-fill"></span>
                  <div className="cp_pulse"></div>
                </CustomLink>
              </div>
              <div className='pin_yokohama'>
                <CustomLink to="#tokyo">
                  <span className="dli-pin-fill"></span>
                  <div className="cp_pulse"></div>
                </CustomLink>
              </div>
              <div className='pin_nagoya'>
                <CustomLink to="#nagoya">
                  <span className="dli-pin-fill"></span>
                  <div className="cp_pulse"></div>
                </CustomLink>
              </div>
              <div className='pin_kyoto'>
                <CustomLink to="#kyoto">
                  <span className="dli-pin-fill"></span>
                  <div className="cp_pulse"></div>
                </CustomLink>
              </div>
              <div className='pin_osaka'>
                <CustomLink to="#osaka">
                  <span className="dli-pin-fill"></span>
                  <div className="cp_pulse"></div>
                </CustomLink>
              </div>
              <div className='pin_kobe'>
                <CustomLink to="#osaka">
                  <span className="dli-pin-fill"></span>
                  <div className="cp_pulse"></div>
                </CustomLink>
              </div>
              <div className='pin_hiroshima'>
                <CustomLink to="#osaka">
                  <span className="dli-pin-fill"></span>
                  <div className="cp_pulse"></div>
                </CustomLink>
              </div>
              <div className='pin_fukuoka'>
                <CustomLink to="#fukuoka">
                  <span className="dli-pin-fill"></span>
                  <div className="cp_pulse"></div>
                </CustomLink>
              </div>
              <div className='pin_okinawa'>
                <CustomLink to="#okinawa">
                  <span className="dli-pin-fill"></span>
                  <div className="cp_pulse"></div>
                </CustomLink>
              </div>
            </section>
          </LWrap>

          <div className="imgWrapper">
            <figure className="">
              <AssetImage src="/assets/images/sp/christmas2024/img_areatop.png" alt="" loading="lazy" />
            </figure>
          </div>

          <section className='lp_area'>
            <LWrap>
              <h3 id="sapporo" className="lp_areaTitle">
                <span className='en'>SAPPORO・SENDAI</span>
                <span className='ja'>札幌・仙台</span>
              </h3>
              <div className="c_summerHotelMediaUnit">
                <div className="c_summerHotelMedia">
                  <div className="imgWrapper">
                    <figure className="mainImg">
                      <AssetImage src="/assets/images/sp/christmas2024/img_sapporo.png" alt="" loading="lazy" />
                    </figure>
                  </div>
                  <div className="body">
                    <p className="label">ザ ロイヤルパーク キャンバス <br className="u_sp" />札幌大通公園</p>
                    <div className="instagram">
                      <Link
                        to="https://www.instagram.com/canvassapporoodoripark/"
                        target="_blank"
                      >
                        <AssetImage
                          src="/assets/images/sp/christmas2024/ico_instagram.svg"
                          alt=""
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <CBtnList
                      data={[
                        {
                          label: "ホテルサイト",
                          icon: "none",
                          link: {
                            href: "https://www.royalparkhotels.co.jp/canvas/sapporoodoripark/",
                            blank: true,
                          },
                        },
                        {
                          label: "宿泊プラン",
                          icon: "none",
                          link: {
                            href: "https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_csapporoodori&form=jp",
                            blank: true,
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="c_summerHotelMedia">
                  <div className="imgWrapper">
                    <figure className="mainImg">
                      <AssetImage src="/assets/images/sp/christmas2024/img_sendai.png" alt="" loading="lazy" />
                    </figure>
                  </div>
                  <div className="body">
                    <p className="label">仙台ロイヤルパークホテル</p>
                    <div className="instagram">
                      <Link
                        to="https://www.instagram.com/sendairoyalparkhotel/"
                        target="_blank"
                      >
                        <AssetImage
                          src="/assets/images/sp/christmas2024/ico_instagram.svg"
                          alt=""
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <CBtnList
                      data={[
                        {
                          label: "ホテルサイト",
                          icon: "none",
                          link: {
                            href: "https://www.srph.co.jp/",
                            blank: true,
                          },
                        },
                        {
                          label: "宿泊プラン",
                          icon: "none",
                          link: {
                            href: "https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_sendai&form=jp&",
                            blank: true,
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>

              <h3 id="tokyo" className="lp_areaTitle">
                <span className='en'>TOKYO・YOKOHAMA</span>
                <span className='ja'>東京・横浜</span>
              </h3>
              <div className="c_summerHotelMediaUnit">
                <div className="c_summerHotelMedia">
                  <div className="imgWrapper">
                    <figure className="mainImg">
                      <AssetImage src="/assets/images/sp/christmas2024/img_rph.png" alt="" loading="lazy" />
                    </figure>
                  </div>
                  <div className="body">
                    <p className="label">ロイヤルパークホテル<br className="u_sp" />（東京・日本橋）</p>
                    <div className="instagram">
                      <Link
                        to="https://www.instagram.com/royalparkhotel.tokyo/"
                        target="_blank"
                      >
                        <AssetImage
                          src="/assets/images/sp/christmas2024/ico_instagram.svg"
                          alt=""
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <CBtnList
                      data={[
                        {
                          label: "ホテルサイト",
                          icon: "none",
                          link: {
                            href: "https://www.rph.co.jp/",
                            blank: true,
                          },
                        },
                        {
                          label: "宿泊プラン",
                          icon: "none",
                          link: {
                            href: "https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_nihonbashi&form=jp",
                            blank: true,
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="c_summerHotelMedia">
                  <div className="imgWrapper">
                    <figure className="mainImg">
                      <AssetImage src="/assets/images/sp/christmas2024/img_yrph.png" alt="" loading="lazy" />
                    </figure>
                  </div>
                  <div className="body">
                    <p className="label">横浜ロイヤルパークホテル</p>
                    <div className="instagram">
                      <Link
                        to="https://www.instagram.com/yokohamaroyalparkhotel/"
                        target="_blank"
                      >
                        <AssetImage
                          src="/assets/images/sp/christmas2024/ico_instagram.svg"
                          alt=""
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <CBtnList
                      data={[
                        {
                          label: "ホテルサイト",
                          icon: "none",
                          link: {
                            href: "https://www.yrph.com/",
                            blank: true,
                          },
                        },
                        {
                          label: "宿泊プラン",
                          icon: "none",
                          link: {
                            href: "https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_yokohama&form=jp",
                            blank: true,
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="c_summerHotelMedia">
                  <div className="imgWrapper">
                    <figure className="mainImg">
                      <AssetImage src="/assets/images/sp/christmas2024/img_tokyoshiodome.png" alt="" loading="lazy" />
                    </figure>
                  </div>
                  <div className="body">
                    <p className="label">ザ ロイヤルパークホテル <br className="u_sp" />アイコニック 東京汐留</p>
                    <div className="instagram">
                      <Link
                        to="https://www.instagram.com/iconictokyoshiodome/"
                        target="_blank"
                      >
                        <AssetImage
                          src="/assets/images/sp/christmas2024/ico_instagram.svg"
                          alt=""
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <CBtnList
                      data={[
                        {
                          label: "ホテルサイト",
                          icon: "none",
                          link: {
                            href: "https://www.royalparkhotels.co.jp/ic/tokyoshiodome/",
                            blank: true,
                          },
                        },
                        {
                          label: "宿泊プラン",
                          icon: "none",
                          link: {
                            href: "https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_icshiodome&form=jp",
                            blank: true,
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="c_summerHotelMedia">
                  <div className="imgWrapper">
                    <figure className="mainImg">
                      <AssetImage src="/assets/images/sp/christmas2024/img_tokyohaneda.png" alt="" loading="lazy" />
                    </figure>
                  </div>
                  <div className="body">
                    <p className="label">ザ ロイヤルパークホテル <br className="u_sp" />東京羽田</p>
                    <div className="instagram">
                      <Link
                        to="https://www.instagram.com/theroyalparkhotel_tokyohaneda/"
                        target="_blank"
                      >
                        <AssetImage
                          src="/assets/images/sp/christmas2024/ico_instagram.svg"
                          alt=""
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <CBtnList
                      data={[
                        {
                          label: "ホテルサイト",
                          icon: "none",
                          link: {
                            href: "https://www.royalparkhotels.co.jp/the/tokyohaneda/",
                            blank: true,
                          },
                        },
                        {
                          label: "宿泊プラン",
                          icon: "none",
                          link: {
                            href: "https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_thehaneda&form=jp",
                            blank: true,
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="c_summerHotelMedia">
                  <div className="imgWrapper">
                    <figure className="mainImg">
                      <AssetImage src="/assets/images/sp/christmas2024/img_ginza6.png" alt="" loading="lazy" />
                    </figure>
                  </div>
                  <div className="body">
                    <p className="label">ザ ロイヤルパークホテル <br className="u_sp" />銀座6丁目</p>
                    <div className="instagram">
                      <Link
                        to="https://www.instagram.com/theroyalparkhotel_ginza6chome/"
                        target="_blank"
                      >
                        <AssetImage
                          src="/assets/images/sp/christmas2024/ico_instagram.svg"
                          alt=""
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <CBtnList
                      data={[
                        {
                          label: "ホテルサイト",
                          icon: "none",
                          link: {
                            href: "https://www.royalparkhotels.co.jp/the/ginza6/",
                            blank: true,
                          },
                        },
                        {
                          label: "宿泊プラン",
                          icon: "none",
                          link: {
                            href: "https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_theginza6&form=jp",
                            blank: true,
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="c_summerHotelMedia">
                  <div className="imgWrapper">
                    <figure className="mainImg">
                      <AssetImage src="/assets/images/sp/christmas2024/img_ginza8.png" alt="" loading="lazy" />
                    </figure>
                  </div>
                  <div className="body">
                    <p className="label">ザ ロイヤルパーク キャンバス <br className="u_sp" />銀座8</p>
                    <div className="instagram">
                      <Link
                        to="https://www.instagram.com/canvasginza8/"
                        target="_blank"
                      >
                        <AssetImage
                          src="/assets/images/sp/christmas2024/ico_instagram.svg"
                          alt=""
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <CBtnList
                      data={[
                        {
                          label: "ホテルサイト",
                          icon: "none",
                          link: {
                            href: "https://www.canvas-ginza8.jp/",
                            blank: true,
                          },
                        },
                        {
                          label: "宿泊プラン",
                          icon: "none",
                          link: {
                            href: "https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_ginza8&form=jp",
                            blank: true,
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="c_summerHotelMedia">
                  <div className="imgWrapper">
                    <figure className="mainImg">
                      <AssetImage src="/assets/images/sp/christmas2024/img_ginzacorridor.png" alt="" loading="lazy" />
                    </figure>
                  </div>
                  <div className="body">
                    <p className="label">ザ ロイヤルパーク キャンバス <br className="u_sp" />銀座コリドー</p>
                    <div className="instagram">
                      <Link
                        to="https://www.instagram.com/canvasginzacorridor/"
                        target="_blank"
                      >
                        <AssetImage
                          src="/assets/images/sp/christmas2024/ico_instagram.svg"
                          alt=""
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <CBtnList
                      data={[
                        {
                          label: "ホテルサイト",
                          icon: "none",
                          link: {
                            href: "https://www.royalparkhotels.co.jp/canvas/ginzacorridor/",
                            blank: true,
                          },
                        },
                        {
                          label: "宿泊プラン",
                          icon: "none",
                          link: {
                            href: "https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_cginzacorridor&form=jp",
                            blank: true,
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="c_summerHotelMedia">
                  <div className="imgWrapper">
                    <figure className="mainImg">
                      <AssetImage src="/assets/images/sp/christmas2024/img_marunouchi.png" alt="" loading="lazy" />
                    </figure>
                  </div>
                  <div className="body">
                    <p className="label">丸ノ内ホテル</p>
                    <div className="instagram">
                      <Link
                        to="https://www.instagram.com/marunouchihotel/"
                        target="_blank"
                      >
                        <AssetImage
                          src="/assets/images/sp/christmas2024/ico_instagram.svg"
                          alt=""
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <CBtnList
                      data={[
                        {
                          label: "ホテルサイト",
                          icon: "none",
                          link: {
                            href: "https://www.marunouchi-hotel.co.jp/",
                            blank: true,
                          },
                        },
                        {
                          label: "宿泊プラン",
                          icon: "none",
                          link: {
                            href: "https://asp.hotel-story.ne.jp/ver3d/planlist.asp?hcod1=01120&hcod2=001&mode=seek&clrmode=true&reffrom=",
                            blank: true,
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>

              <h3 id="nagoya" className="lp_areaTitle">
                <span className='en'>NAGOYA</span>
                <span className='ja'>名古屋</span>
              </h3>
              <div className="c_summerHotelMediaUnit">
                <div className="c_summerHotelMedia">
                  <div className="imgWrapper">
                    <figure className="mainImg">
                      <AssetImage src="/assets/images/sp/christmas2024/img_icnagoya.png" alt="" loading="lazy" />
                    </figure>
                  </div>
                  <div className="body">
                    <p className="label">ザ ロイヤルパークホテル <br className="u_sp" />アイコニック 名古屋</p>
                    <div className="instagram">
                      <Link
                        to="https://www.instagram.com/iconicnagoya/"
                        target="_blank"
                      >
                        <AssetImage
                          src="/assets/images/sp/christmas2024/ico_instagram.svg"
                          alt=""
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <CBtnList
                      data={[
                        {
                          label: "ホテルサイト",
                          icon: "none",
                          link: {
                            href: "https://www.royalparkhotels.co.jp/ic/nagoya/",
                            blank: true,
                          },
                        },
                        {
                          label: "宿泊プラン",
                          icon: "none",
                          link: {
                            href: "https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_icnagoya&form=jp",
                            blank: true,
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="c_summerHotelMedia">
                  <div className="imgWrapper">
                    <figure className="mainImg">
                      <AssetImage src="/assets/images/sp/christmas2024/img_cvnagoya.png" alt="" loading="lazy" />
                    </figure>
                  </div>
                  <div className="body">
                    <p className="label">ザ ロイヤルパーク キャンバス <br className="u_sp" />名古屋</p>
                    <div className="instagram">
                      <Link
                        to="https://www.instagram.com/canvasnagoya/"
                        target="_blank"
                      >
                        <AssetImage
                          src="/assets/images/sp/christmas2024/ico_instagram.svg"
                          alt=""
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <CBtnList
                      data={[
                        {
                          label: "ホテルサイト",
                          icon: "none",
                          link: {
                            href: "https://www.royalparkhotels.co.jp/canvas/nagoya/",
                            blank: true,
                          },
                        },
                        {
                          label: "宿泊プラン",
                          icon: "none",
                          link: {
                            href: "https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_cnagoya&form=jp",
                            blank: true,
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>

              <h3 id="kyoto" className="lp_areaTitle">
                <span className='en'>KYOTO</span>
                <span className='ja'>京都</span>
              </h3>
              <div className="c_summerHotelMediaUnit">
                <div className="c_summerHotelMedia">
                  <div className="imgWrapper">
                    <figure className="mainImg">
                      <AssetImage src="/assets/images/sp/christmas2024/img_ickyoto.png" alt="" loading="lazy" />
                    </figure>
                  </div>
                  <div className="body">
                    <p className="label">ザ ロイヤルパークホテル <br className="u_sp" />アイコニック 京都</p>
                    <div className="instagram">
                      <Link
                        to="https://www.instagram.com/iconickyoto/"
                        target="_blank"
                      >
                        <AssetImage
                          src="/assets/images/sp/christmas2024/ico_instagram.svg"
                          alt=""
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <CBtnList
                      data={[
                        {
                          label: "ホテルサイト",
                          icon: "none",
                          link: {
                            href: "https://www.royalparkhotels.co.jp/ic/kyoto/",
                            blank: true,
                          },
                        },
                        {
                          label: "宿泊プラン",
                          icon: "none",
                          link: {
                            href: "https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_ickyoto&form=jp",
                            blank: true,
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="c_summerHotelMedia">
                  <div className="imgWrapper">
                    <figure className="mainImg">
                      <AssetImage src="/assets/images/sp/christmas2024/img_kyotosanjo.png" alt="" loading="lazy" />
                    </figure>
                  </div>
                  <div className="body">
                    <p className="label">ザ ロイヤルパークホテル <br className="u_sp" />京都三条</p>
                    <div className="instagram">
                      <Link
                        to="https://www.instagram.com/theroyalparkhotel_kyoto/"
                        target="_blank"
                      >
                        <AssetImage
                          src="/assets/images/sp/christmas2024/ico_instagram.svg"
                          alt=""
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <CBtnList
                      data={[
                        {
                          label: "ホテルサイト",
                          icon: "none",
                          link: {
                            href: "https://www.royalparkhotels.co.jp/the/kyotosanjo/",
                            blank: true,
                          },
                        },
                        {
                          label: "宿泊プラン",
                          icon: "none",
                          link: {
                            href: "https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_thesanjo&form=jp",
                            blank: true,
                          },
                        },
                      ]}
                    />
                  </div>
                </div>

                <div className="c_summerHotelMedia">
                  <div className="imgWrapper">
                    <figure className="mainImg">
                      <AssetImage src="/assets/images/sp/christmas2024/img_kyotoumekoji.png" alt="" loading="lazy" />
                    </figure>
                  </div>
                  <div className="body">
                    <p className="label">ザ ロイヤルパークホテル <br className="u_sp" />京都梅小路</p>
                    <div className="instagram">
                      <Link
                        to="https://www.instagram.com/theroyalparkhotel_kyoto/"
                        target="_blank"
                      >
                        <AssetImage
                          src="/assets/images/sp/christmas2024/ico_instagram.svg"
                          alt=""
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <CBtnList
                      data={[
                        {
                          label: "ホテルサイト",
                          icon: "none",
                          link: {
                            href: "https://www.royalparkhotels.co.jp/the/kyotoumekoji/",
                            blank: true,
                          },
                        },
                        {
                          label: "宿泊プラン",
                          icon: "none",
                          link: {
                            href: "https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_theumekoji&form=jp",
                            blank: true,
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="c_summerHotelMedia">
                  <div className="imgWrapper">
                    <figure className="mainImg">
                      <AssetImage src="/assets/images/sp/christmas2024/img_kyoatonijo.png" alt="" loading="lazy" />
                    </figure>
                  </div>
                  <div className="body">
                    <p className="label">ザ ロイヤルパーク キャンバス <br className="u_sp" />京都二条</p>
                    <div className="instagram">
                      <Link
                        to="https://www.instagram.com/canvaskyotonijo/"
                        target="_blank"
                      >
                        <AssetImage
                          src="/assets/images/sp/christmas2024/ico_instagram.svg"
                          alt=""
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <CBtnList
                      data={[
                        {
                          label: "ホテルサイト",
                          icon: "none",
                          link: {
                            href: "https://www.royalparkhotels.co.jp/canvas/kyotonijo/",
                            blank: true,
                          },
                        },
                        {
                          label: "宿泊プラン",
                          icon: "none",
                          link: {
                            href: "https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_cnijo&form=jp",
                            blank: true,
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>

              <h3 id="osaka" className="lp_areaTitle">
                <span className='en'>OSAKA・KOBE・HIROSHIMA</span>
                <span className='ja'>大阪・神戸・広島</span>
              </h3>
              <div className="c_summerHotelMediaUnit">
                <div className="c_summerHotelMedia">
                  <div className="imgWrapper">
                    <figure className="mainImg">
                      <AssetImage src="/assets/images/sp/christmas2024/img_osakamidosuji.png" alt="" loading="lazy" />
                    </figure>
                  </div>
                  <div className="body">
                    <p className="label">ザ ロイヤルパークホテル <br className="u_sp" />アイコニック 大阪御堂筋</p>
                    <div className="instagram">
                      <Link
                        to="https://www.instagram.com/iconicosakamidosuji/"
                        target="_blank"
                      >
                        <AssetImage
                          src="/assets/images/sp/christmas2024/ico_instagram.svg"
                          alt=""
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <CBtnList
                      data={[
                        {
                          label: "ホテルサイト",
                          icon: "none",
                          link: {
                            href: "https://www.royalparkhotels.co.jp/ic/osakamidosuji/",
                            blank: true,
                          },
                        },
                        {
                          label: "宿泊プラン",
                          icon: "none",
                          link: {
                            href: "https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_icmidosuji&form=jp",
                            blank: true,
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="c_summerHotelMedia">
                  <div className="imgWrapper">
                    <figure className="mainImg">
                      <AssetImage src="/assets/images/sp/christmas2024/img_osakakitahama.png" alt="" loading="lazy" />
                    </figure>
                  </div>
                  <div className="body">
                    <p className="label">ザ ロイヤルパーク キャンバス <br className="u_sp" />大阪北浜</p>
                    <div className="instagram">
                      <Link
                        to="https://www.instagram.com/canvasosakakitahama/"
                        target="_blank"
                      >
                        <AssetImage
                          src="/assets/images/sp/christmas2024/ico_instagram.svg"
                          alt=""
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <CBtnList
                      data={[
                        {
                          label: "ホテルサイト",
                          icon: "none",
                          link: {
                            href: "https://www.royalparkhotels.co.jp/canvas/osakakitahama/",
                            blank: true,
                          },
                        },
                        {
                          label: "宿泊プラン",
                          icon: "none",
                          link: {
                            href: "https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_ckitahama&form=jp",
                            blank: true,
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="c_summerHotelMedia">
                  <div className="imgWrapper">
                    <figure className="mainImg">
                      <AssetImage src="/assets/images/sp/christmas2024/img_kobesannomiya.png" alt="" loading="lazy" />
                    </figure>
                  </div>
                  <div className="body">
                    <p className="label">ザ ロイヤルパーク キャンバス <br className="u_sp" />神戸三宮</p>
                    <div className="instagram">
                      <Link
                        to="https://www.instagram.com/canvaskobesannomiya/"
                        target="_blank"
                      >
                        <AssetImage
                          src="/assets/images/sp/christmas2024/ico_instagram.svg"
                          alt=""
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <CBtnList
                      data={[
                        {
                          label: "ホテルサイト",
                          icon: "none",
                          link: {
                            href: "https://www.royalparkhotels.co.jp/canvas/kobesannomiya/",
                            blank: true,
                          },
                        },
                        {
                          label: "宿泊プラン",
                          icon: "none",
                          link: {
                            href: "https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_csannomiya&form=jp",
                            blank: true,
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="c_summerHotelMedia">
                  <div className="imgWrapper">
                    <figure className="mainImg">
                      <AssetImage src="/assets/images/sp/christmas2024/img_hiroshima.png" alt="" loading="lazy" />
                    </figure>
                  </div>
                  <div className="body">
                    <p className="label">ザ ロイヤルパークホテル <br className="u_sp" />広島リバーサイド</p>
                    <div className="instagram">
                      <Link
                        to="https://www.instagram.com/theroyalpark_hiroshima/"
                        target="_blank"
                      >
                        <AssetImage
                          src="/assets/images/sp/christmas2024/ico_instagram.svg"
                          alt=""
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <CBtnList
                      data={[
                        {
                          label: "ホテルサイト",
                          icon: "none",
                          link: {
                            href: "https://www.royalparkhotels.co.jp/the/hiroshimars/",
                            blank: true,
                          },
                        },
                        {
                          label: "宿泊プラン",
                          icon: "none",
                          link: {
                            href: "https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_thehiroshima&form=jp",
                            blank: true,
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>

              <h3 id="fukuoka" className="lp_areaTitle">
                <span className='en'>FUKUOKA</span>
                <span className='ja'>福岡</span>
              </h3>
              <div className="c_summerHotelMediaUnit">
                <div className="c_summerHotelMedia">
                  <div className="imgWrapper">
                    <figure className="mainImg">
                      <AssetImage src="/assets/images/sp/christmas2024/img_fukuoka.png" alt="" loading="lazy" />
                    </figure>
                  </div>
                  <div className="body">
                    <p className="label">ザ ロイヤルパークホテル 福岡</p>
                    <div className="instagram">
                      <Link
                        to="https://www.instagram.com/theroyalparkhotel_fuk/"
                        target="_blank"
                      >
                        <AssetImage
                          src="/assets/images/sp/christmas2024/ico_instagram.svg"
                          alt=""
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <CBtnList
                      data={[
                        {
                          label: "ホテルサイト",
                          icon: "none",
                          link: {
                            href: "https://www.royalparkhotels.co.jp/the/fukuoka/",
                            blank: true,
                          },
                        },
                        {
                          label: "宿泊プラン",
                          icon: "none",
                          link: {
                            href: "https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_thefukuoka&form=jp",
                            blank: true,
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="c_summerHotelMedia">
                  <div className="imgWrapper">
                    <figure className="mainImg">
                      <AssetImage src="/assets/images/sp/christmas2024/img_fukuokanakasu.png" alt="" loading="lazy" />
                    </figure>
                  </div>
                  <div className="body">
                    <p className="label">ザ ロイヤルパーク キャンバス <br className="u_sp" />福岡中洲</p>
                    <div className="instagram">
                      <Link
                        to="https://www.instagram.com/canvasfukuokanakasu/"
                        target="_blank"
                      >
                        <AssetImage
                          src="/assets/images/sp/christmas2024/ico_instagram.svg"
                          alt=""
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <CBtnList
                      data={[
                        {
                          label: "ホテルサイト",
                          icon: "none",
                          link: {
                            href: "https://www.royalparkhotels.co.jp/canvas/fukuokanakasu/",
                            blank: true,
                          },
                        },
                        {
                          label: "宿泊プラン",
                          icon: "none",
                          link: {
                            href: "https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_cfukuokanakasu&form=jp",
                            blank: true,
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>

              <h3 id="okinawa" className="lp_areaTitle">
                <span className='en'>OKINAWA</span>
                <span className='ja'>沖縄</span>
              </h3>
              <div className="c_summerHotelMediaUnit">
                <div className="c_summerHotelMedia">
                  <div className="imgWrapper">
                    <figure className="mainImg">
                      <AssetImage src="/assets/images/sp/christmas2024/img_atollemerald.png" alt="" loading="lazy" />
                    </figure>
                  </div>
                  <div className="body">
                    <p className="label">ホテルアトールエメラルド<br className="u_sp" />宮古島</p>
                    <div className="instagram">
                      <Link
                        to="https://www.instagram.com/hotelatollemerald/"
                        target="_blank"
                      >
                        <AssetImage
                          src="/assets/images/sp/christmas2024/ico_instagram.svg"
                          alt=""
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <CBtnList
                      data={[
                        {
                          label: "ホテルサイト",
                          icon: "none",
                          link: {
                            href: "https://www.atollemerald.jp/",
                            blank: true,
                          },
                        },
                        {
                          label: "宿泊プラン",
                          icon: "none",
                          link: {
                            href: "https://asp.hotel-story.ne.jp/ver3d/planlist.asp?hcod1=05610&hcod2=001&mode=seek&clrmode=true&reffrom=",
                            blank: true,
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </LWrap>
          </section>


          <section className='instagramUnit'>
            <div className="instagram">
              <LWrap>
                <Link
                  to="https://www.instagram.com/royalparkhotels__official/"
                  target="_blank"
                >
                  <AssetImage
                    src="/assets/images/sp/summer2024/instagram.png"
                    alt=""
                    loading="lazy"
                  />
                </Link>
              </LWrap>
            </div>
          </section>
        </div>
      </main>
    </LayoutLp>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
